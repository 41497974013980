
import { defineComponent } from "vue";
import { mapGetters, mapActions, mapMutations } from "vuex";

import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import SalesOrder from "@/types/salesorder";
import Dialog from "primevue/dialog";
import Checkbox from "primevue/checkbox";
import MultiSelect from "primevue/multiselect";

export default defineComponent({
  name: "PrintableDataTable",
  created() {
    this.items = this.getOrders;
    this.defaultColumns = this.getDefaultColumns;
    this.availableColumns = this.getAvailableColumns;
    this.allColumns = this.getAvailableColumns;
    const sortedColumns = [...this.availableColumns];
    sortedColumns.sort((a, b) => a.header.localeCompare(b.header));
    this.sortedAvailableColumns = sortedColumns;
    this.selectedItems = this.defaultColumns.map((item) => item["field"]);
  },
  mounted() {
    const myQueryPrint = this.$route.query.print;
    const myQueryShowSelection = this.$route.query.showSelection;
    if (myQueryPrint) {
      // display the column selection dialog
      this.actionName = "Preview";
      if (myQueryShowSelection) {
        this.showSelectionDialog = true;
      } else {
        this.dataTableVisible = true;
        window.print();
      }
    }
    const myQueryExport = this.$route.query.export;

    if (myQueryExport) {
      this.actionName = "Export";
      if (myQueryShowSelection) {
        this.showSelectionDialog = true;
      } else {
        this.dataTableVisible = true;
        (this.$refs.dtExport as any).exportCSV();
        window.close();
      }
    }
  },

  components: {
    DataTable,
    Column,
    Dialog,
    Checkbox,
    Button,
    MultiSelect,
  },
  computed: {
    ...mapGetters({
      getOrders: "printableDatatable/getOrders",
      getDefaultColumns: "printableDatatable/getDefaultColumns",
      getAvailableColumns: "printableDatatable/getAvailableColumns",
      getExportName: "printableDatatable/getExportFilename",
    }),
  },

  data() {
    return {
      items: [] as Array<SalesOrder>,
      defaultColumns: [] as Array<any>,
      availableColumns: [] as Array<any>,
      allColumns: [] as Array<any>,
      selectedColumns: null,
      sortedAvailableColumns: [] as Array<any>,
      selectedItems: [] as Array<any>,
      printDatatable: false,
      exportDatatable: false,
      dataTableVisible: false,
      showSelectionDialog: false,
      selectAll: false,
      actionName: "Preview",
    };
  },
  methods: {
    cancelAction() {
      window.close();
    },
    showDialog() {
      this.showSelectionDialog = true;
    },

    toggleSelectAll() {
      if (this.selectAll) {
        this.selectedItems = [];
        this.selectAll = false;
      } else {
        this.selectedItems = this.availableColumns.map((item) => item["field"]);
        this.selectAll = true;
      }
    },
    saveSelection() {
      this.showSelectionDialog = false;

      this.availableColumns = this.availableColumns.filter((column) => {
        return this.selectedItems.includes(column.field);
      });

      this.dataTableVisible = true;

      if (this.actionName === "Preview") {
        // Wait for the next DOM update cycle before printing
        this.$nextTick(() => {
          //    window.print();
        });
      } else {
        // Wait for the next DOM update cycle before printing
        this.$nextTick(() => {
          (this.$refs.dtExport as any).exportCSV();
          window.close();
        });
      }
    },
    printDataTable() {
      this.$nextTick(() => {
        window.print();
      });
    },
    exportDataTable() {
      (this.$refs.dtExport as any).exportCSV();
      window.close();
    },
  },
});
